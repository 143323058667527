import React from "react"
import { Col, Card, Badge, Row } from "react-bootstrap"
import { Link } from "gatsby"
const CaseStudyCard = ({ heading, imageSrc, tag, redirect, cardHeight }) => {
  return (
    <>
      <Card style={{ height: cardHeight }}>
        <Card.Img style={{ height: "200px" }} variant="top" src={imageSrc} />
        <Card.Body>
          <h5>
            <Badge className="text-capitalize blog-tag">{tag}</Badge>
          </h5>
          <Card.Title as="p" className="font-weight-bold text-justify">
            {heading}
          </Card.Title>
          <Row>
            <Col>
              <Link
                to={redirect}
                className="link-no-style btn"
                style={{ color: "#eebb6b" }}
              >
                Read More <div className="line-standard"></div>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default CaseStudyCard
