import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import { Container, Row, Col } from 'react-bootstrap'
import { productEngineering } from '../staticdata/threeTData'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
class ProductEngineeringContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Product Development "
                    bannerImage={banner}
                    text="Build next-generation digital products, leveraging our product development DNA." />
                <Container>
                    <Row className="py-5 px-3">
                        <Col>
                            <p className="text-justify initial-paragrapgh">
                                Businesses which are using technology to deliver business value in today’s volatile and uncertain environments have been growing at exponential speeds. Agility, innovation, thinking on the feet and flexibility are being imbibed by businesses into their business modelsand processes. Customers are becoming more demanding and unforgiving in their interaction with businesses expecting them to deliver perfectly every time.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <ServicesNewComponent
                    heading="Offerings & solutions"
                    height="250px"
                    bgColor='#ffffff'
                    page="services"
                    data={productEngineering} />
                <CaseStudyComponent />
                <TestimonialComponent />
            </>
        )
    }
}

export default ProductEngineeringContainer