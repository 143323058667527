import React from "react"
import { Carousel, Container, Row, Col } from "react-bootstrap"
import { caseStudyData } from "../../staticdata/caseStudySectionData"
import CaseStudyCard from "../homepage/CaseStudyCard"

const CaseStudyComponent = () => {
  return (
    <Container fluid style={{ background: "#ffffff" }}>
      <Container fluid>
        <Carousel
          className="case-study-section d-none d-md-block "
          interval={3000}
          controls={false}
          indicators={caseStudyData.length < 3 ? true : false}
          defaultActiveIndex={1}
        >
          {caseStudyData.map((carouselItem, index1) => (
            <Carousel.Item key={index1 + "testimonial"}>
              <Container fluid>
                <Row className="p-5">
                  <Col className="carousel-columns">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="line"></div>
                      <div>
                        <h6 className="carousel-section-subheading">
                          WHAT WE HAVE DONE
                        </h6>
                      </div>
                    </div>
                    <h2>CASE STUDY</h2>
                    <Row className="p-4">
                      {carouselItem &&
                        carouselItem.map((caseStudy, index) => (
                          <Col
                            className="carousel-columns-images"
                            md="4"
                            key={"casestudy" + index}
                          >
                            <CaseStudyCard
                              cardHeight="400px"
                              key={index + "casestudy"}
                              heading={caseStudy.heading}
                              imageSrc={caseStudy.caseStudyImage}
                              redirect={caseStudy.redirect}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel
          className="case-study-section d-md-none d-lg-none d-xl-none"
          interval={4000}
          controls={false}
          indicators={false}
          defaultActiveIndex={0}
        >
          {caseStudyData &&
            caseStudyData.map((carouselItem, index1) => (
              <Carousel.Item key={index1 + "testimonial"}>
                <Container fluid>
                  <Row className="px-2 py-3">
                    <Col className="carousel-columns">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="line"></div>
                        <div>
                          <h6 className="carousel-section-subheading">
                            WHAT WE HAVE DONE
                          </h6>
                        </div>
                      </div>
                      <h2>CASE STUDY</h2>
                      {carouselItem &&
                        carouselItem.map((caseStudy, index) => (
                          <Row className="mt-3" key={"casestudy" + index}>
                            <Col className="carousel-columns-images">
                              <CaseStudyCard
                                cardHeight="350px"
                                key={index + "casestudy"}
                                heading={caseStudy.heading}
                                imageSrc={caseStudy.caseStudyImage}
                                redirect={caseStudy.redirect}
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
            ))}
        </Carousel>
      </Container>
    </Container>
  )
}

export default CaseStudyComponent
