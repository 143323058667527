import React from 'react'
import { Carousel, Breadcrumb } from "react-bootstrap"
const BannerImage = ({ activeTab, bannerImage,text,styling }) => {
    return (
        <>
            <Carousel indicators={false} controls={false}>
                <Carousel.Item>
                    <img
                        className="d-block w-100 top-banner"
                        src={bannerImage}
                        alt=" Not Found"
                    />
                    <Carousel.Caption>
                        <h1 className={styling ? styling : ""}>{activeTab}</h1>
                        {text ? <p className="text-capitalize">{text}</p> :
                        <Breadcrumb className="d-flex justify-content-center">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{activeTab}</Breadcrumb.Item>
                            </Breadcrumb>}

                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default BannerImage