import banner from '../images/slicing/home/aboutus.png'
import teamWork from '../images/slicing/Startup Studio/team-work.png'
import time from '../images/slicing/Startup Studio/time.png'
import technology from '../images/slicing/Startup Studio/technology.png'
import hosting from '../images/slicing/service/webhosting.png'
export const startupStudio = [{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TIME',
    description: `In an eco-system, which is dependent on speed, we drastically reduce the ‘ideation to prototype’ time-gap, with an MVP launch ina period of just 2 to 3 months.`,
    imageSrc: time,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TEAMWORK',
    description: `Nothing gives us more pleasure than watching ideas succeed. We support,we guide, we suggest, we execute and we provide solutions as part of your integral team, every single time.`,
    imageSrc: teamWork,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TECHNOLOGY',
    description: `With an unmatched expertise in the field of digital, we help founders choose the right technology.From full capabilities of technology stack to low-code, no-code solutions like Microsoft PowerApps, webring the latest and the most agile technologies to the table. `,
    imageSrc: technology,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
}]

export const testingStrategy = [{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TIME',
    description: `In an eco-system, which is dependent on speed, we drastically reduce the‘ideation to prototype’ time-gap, with an MVP launch ina period of just 2 to 3 months.`,
    imageSrc: banner,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TEAMWORK',
    description: `Nothing gives us more pleasure than watching ideas succeed. We support,we guide, we suggest, we execute and we provide solutions as part of your integral team, every single time.`,
    imageSrc: banner,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Launch your Venture successfully with the 3T’s in the right place.',
    heading: 'TECHNOLOGY',
    description: `With an unmatched expertise in the field of digital, we help founders choose the right technology.From full capabilities of technology stack to low-code, no-code solutions like Microsoft PowerApps, webring the latest and the mostagile technologies to the table. `,
    imageSrc: banner,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
}]

export const productEngineering = [[{
    subHeading: 'Offerings and Solutions',
    heading: 'Scaled Co-Engineering Agile Pods',
    description: `Accelerate strategic development initiatives with engineering talent from GTEN.`,
    imageSrc: banner,
    altText: 'Image Not Found',
    readMore: false,
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Offerings and Solutions',
    heading: 'Next-Generation Product & Platform Development',
    description: `Build your new product form idea to production deployment and monitoring, we are your trusted partner to deliver on it.`,
    imageSrc: banner,
    altText: 'Image Not Found',
    readMore: false,
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'Offerings and Solutions',
    heading: 'Rapid MVP Development',
    description: `Convert your vision into real time working MVP using our proven accelerated MVP development .`,
    imageSrc: banner,
    altText: 'Image Not Found',
    readMore: false,
    specifications: ['Analytics & Data', 'Certified Company']
}],
[
    {
        subHeading: 'Offerings and Solutions',
        heading: 'Test Engineering',
        description: `We help to establish the right testing strategy to make right decision on where to test, when totest and how to test. Global product companies rely on our engineers for product quality.`,
        imageSrc: banner,
        altText: 'Image Not Found',
        readMore: "/testingservice/",
        specifications: ['Analytics & Data', 'Certified Company']
    },
    {
        subHeading: 'Offerings and Solutions',
        heading: 'Integrate Eco-system',
        description: `Every business today leverages an ecosystem of partners, providers and regulatory system to deliver end to end customer journey and experience. With our wide experience of integrating multiple industry solutions, we can make it smooth and fast for your teams.`,
        imageSrc: banner,
        altText: 'Image Not Found',
        readMore: false,
        specifications: ['Analytics & Data', 'Certified Company']
    }
]
]

export const digitalEngineering = [[
    {
        subHeading: 'GTEN Product Engineering Capabilities',
        heading: 'Product Development',
        description: `Software development and engineering services centered around microservices architecture, API-led connectivity, Design Thinking, Agile, and automation to build next-generation software products and digital solutions.`,
        imageSrc: banner,
        readMore: '/productdevelopment/',
        altText: 'Image Not Found',
        specifications: ['Analytics & Data', 'Certified Company']
    },
    {
        subHeading: 'GTEN Product Engineering Capabilities',
        heading: 'Low Code Intelligent Automation',
        imageSrc: hosting,
        altText: 'Image Not Found',
        specifications: ['Analytics & Data', 'Certified Company'],
        description: 'We bring relevant domain experience along with a diverse set of solutions to help clients derive efficiency, optimization and cost savings by leveraging automation – including low code, RPA and AI-driven automation. Our expertise and partner ecosystem create value across the enterprise from Core IT and application development to all business processes like HR, sales and finance.'
    },
    {
        subHeading: 'GTEN Product Engineering Capabilities',
        heading: 'Innovation Acceleration',
        description: `GTEN Innovation frameworks in conjunction with our wide start-up experience being agility and continuous evolution mindset that drive divergent thinking, product visioning to rapidly build platforms for digital world and convert ideas into real business outcomes.`,
        imageSrc: banner,
        altText: 'Image Not Found',
        specifications: ['Analytics & Data', 'Certified Company']
    }],
[{
        subHeading: 'GTEN Product Engineering Capabilities',
        heading: 'Sustenance & Support',
        description: `Sustenance engineering and support services to reenergise legacy products with a focus on improving revenue, efficiency and customer delight.`,
        imageSrc: banner,
        altText: 'Image Not Found',
        specifications: ['Analytics & Data', 'Certified Company']
    }
]]