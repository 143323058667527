import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'
const descriptionTextStyle = {
    textAlign: 'justify'
}
class ServicesNewComponent extends Component {
    state = {
        selectedService: null,
    }
    toggleServiceModal = (selectedService) => {
        this.setState({ selectedService })
    }
    render() {
        const { data,height, heading,page,subheading } = this.props
        const { selectedService } = this.state
        return (
            <Container fluid style={{ background: '#f7f6f9' }}>
                <Container className="py-5">
                    <Row>
                        <Col>
                            <h3 className="font-weight-bold text-uppercase text-standard">{heading}</h3>
                        </Col>
                    </Row>
                    {subheading && <Row>
                        <Col>
                            <h2 className="font-weight-bold text-uppercase">{subheading}</h2>
                        </Col>
                    </Row>}
                    {
                        data.map((serviceRow, rowIndex) => {
                            return (
                                <Row key={`row_${rowIndex}`} className={(rowIndex === 1 && 'mt-3') || 'pt-4'}>
                                    {serviceRow.map((service, serviceNo) => (
                                        <Col key={`col_${serviceNo}`} md="4" sm="12" xs="12" className="mt-2">
                                            <Card
                                                onMouseOver={() => this.toggleServiceModal(page === 'noheading' ? service.noheading : service.heading)}
                                                style={{ border: '0px', height: height }}
                                                onMouseOut={() => this.toggleServiceModal(null)}
                                                className={((service.heading && selectedService === service.heading) || (service.noheading && selectedService === service.noheading)) && 'standard-background'}>
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold">{service.heading}</Card.Title>
                                                    <Card.Text style={descriptionTextStyle}>{service.description}
                                                    <br/><br/>
                                                    {service.readMore && <a style={{paddingLeft:'10px',textDecoration:'none'}} 
                                                    className={`btn btn-primary btn-sm ${selectedService === service.heading ? "text-light service-link" : 'text-light'}`} 
                                                    key={service.readMore} 
                                                    href={service.readMore}>Read More</a>}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            )
                        })
                    }
                </Container>

            </Container>
        );
    }
}

export default ServicesNewComponent;