import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ProductEngineeringContainer from "../containers/ProductEngineeringCOntainer"

const ProductEngineering = (props) => (
  <Layout 
  pageInfo={{ pageName: "Product Engineering" }} 
  styleClass="home-page" 
  location={'/productdevelopment/'}>
    <SEO title="Product Engineering" />
    <ProductEngineeringContainer />
  </Layout>
)

export default ProductEngineering
